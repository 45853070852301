<template>
  <div class="detail" v-if="info.url">
    <iframe name="child" id="benefitnotice_iframe" width="100%" height="100vh" frameborder="0" scrolling="yes"
            :src="info.url + '&from=preview'"
    ></iframe>
  </div>
</template>

<script>
  import home from '../../api/home';
import { Toast } from 'vant';

  export default {
    name: 'detail',
    data() {
      return {
        viewUrl: '',
        taskId: '',
        info: {},
        shareUrl: ''
      };
    },
    methods: {
      async getUrl() {
        let { code, data } = await home.getAIUrl({
          tenantId: JSON.parse(localStorage.getItem('platform_user')).tenantId,
          saleName: JSON.parse(localStorage.getItem('platform_user')).name,
          salePhone: JSON.parse(localStorage.getItem('platform_user')).mobile,
          saleUserId: JSON.parse(localStorage.getItem('platform_role')).saleUserId,
          estateId: localStorage.getItem('platform_estateId'),
          opId: localStorage.getItem('platform_opId'),
        });
        if (code === '0') {
          this.info = data;
        }
      },
      shareChange() {
        this.$wx.onMenuShareAppMessage({
          title: `${this.info.aiCardInfo.saleName}`, // 分享标题
          desc: this.info.aiCardInfo.slogan || this.info.aiCardInfo.brief || '查看详情', // 分享描述
          link: this.info.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: this.info.aiCardInfo.avatar, // 分享图标
          success: (res)=> {
            // 用户确认分享后执行的回调函数
            console.log('转发---res', res);
            this.showShareTip = false;
            Toast('分享成功');
          },
          cancel: ()=> {
            this.showShareTip = false;
            // 用户取消分享后执行的回调函数
          }
        });
        this.$wx.onMenuShareWechat({
          title: `${this.info.aiCardInfo.saleName}`, // 分享标题
          desc: this.info.aiCardInfo.slogan || this.info.aiCardInfo.brief || '查看详情', // 分享描述
          link: this.info.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: this.info.aiCardInfo.avatar, // 分享图标
          success: (res)=> {
            // 用户确认分享后执行的回调函数
            console.log('微信---res', res);
            this.showShareTip = false;
            Toast('分享成功');
          },
          cancel: ()=> {
            this.showShareTip = false;
            // 用户取消分享后执行的回调函数
          }
        });
        this.$wx.onMenuShareTimeline({
          title: `${this.info.aiCardInfo.saleName}`, // 分享标题
          desc: this.info.aiCardInfo.slogan || this.info.aiCardInfo.brief || '查看详情', // 分享描述
          link: this.info.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: this.info.aiCardInfo.avatar, // 分享图标
          success: (res)=> {
            // 用户确认分享后执行的回调函数
            console.log('分享到朋友圈--res', res);
            Toast('分享成功');
          },
          cancel: ()=> {
            // 用户取消分享后执行的回调函数
          }
        });
      }
    },
    async mounted() {
      this.$wx.hideMenuItems({
        menuList: ['menuItem:setFont', 'menuItem:share:email', 'menuItem:openWithSafari', 'menuItem:favorite', 'menuItem:copyUrl', 'menuItem:openWithSafari', 'menuItem:share:email'] // 要隐藏的菜单项
      });
      await this.getUrl();
      this.shareChange();
    }
  };
</script>

<style lang="less" scoped>
.detail{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  iframe{
    width: 100%;
    height: 100vh;
  }
}
</style>
